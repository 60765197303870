
import * as React from "react"
import { graphql, Link } from "gatsby"
import {GatsbyImage} from 'gatsby-plugin-image'

import { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import Layout from "../components/layout"
import Seo from "../components/seo"

const ProductPage = ({ pageContext, data }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);

const mapData: Array<any> = data.allFile.nodes
console.log(mapData[0])
const thumbs = mapData.map((arNode, index) => (
  <div
    className="mb-5 relative"
    style={{ breakInside: "avoid" }}
    key={index}
  >
    <GatsbyImage
      image={arNode.childImageSharp.gatsbyImageData}
      alt={arNode.relativeDirectory}
      onClick={() => {
        setSelectedImageIndex(index);
        setLightboxOpen(true);
      }}
    />
			<div className="bg-black text-gray-200 p-2 text-xs font-bold uppercase bg-opacity-70 absolute bottom-0 left-0 right-0">
				<h2>{(arNode.relativeDirectory as String).replace(/^.*\/(.*)$/, "$1").replace(/_/g," ")}</h2>
			</div>
		</div>
	) )



		return(

	<Layout>

		<h3 className="text-sm py-6 font-bold uppercase text-gray-600">
			{(pageContext.title as String).replace(/^.*\/(.*)$/, "$1").replace(/_/g," ")}
		</h3>
	
	
	
	
	<div dangerouslySetInnerHTML={{__html: pageContext.desc}}> 
		
	</div>
	<div>
		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">
			{thumbs}

		</div>
	</div>
	
	
    {lightboxOpen && (
      <Lightbox
        mainSrc={mapData[selectedImageIndex].childImageSharp.gatsbyImageData.images.fallback.src}
        onCloseRequest={() => setLightboxOpen(false)}
      />
    )}
	
	</Layout>
)
}
export const query = graphql`
	query($title: String!) {
	allFile(filter: {
		sourceInstanceName: {eq: "products"},
		ext: {eq: ".jpg"},
		name: {ne: "thumb"},
		relativeDirectory:{eq: $title}
		}) {
		nodes {
		relativeDirectory
		childImageSharp {
      	  gatsbyImageData(
				width:600
				placeholder: BLURRED
			)
      	}
		}
	}
	}
	`

	export default ProductPage